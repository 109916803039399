import { Select } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import api from "../../api/rest";

interface IProps {
  collectionName: string;
  displayField: any;
  relationField: any;
}

const RelationInput = ({ collectionName, displayField, ...rest }: IProps) => {
  const [model, setModel] = useState<any>(null);
  const dataQuery = useQuery(collectionName, api(collectionName).find);
  const list =
    (model &&
      ((dataQuery.isFetched &&
        dataQuery.data &&
        dataQuery.data[model.resFieldName]) ||
        dataQuery.data ||
        [])) ||
    [];

  useEffect(() => {
    if (collectionName) {
      const getModel = async () => {
        const model = (await import(`../../models/${collectionName}`)).default;
        setModel(model);
      };
      getModel();
    }
  }, [collectionName]);

  return (
    model && (
      <Select loading={dataQuery.isLoading} size="large" {...rest}>
        {list?.map((d: any) => (
          <Select.Option value={d[rest?.relationField]}>
            {typeof displayField === "string"
              ? d[displayField]
              : displayField(d)}
          </Select.Option>
        ))}
      </Select>
    )
  );
};

export default RelationInput;
