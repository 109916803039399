import { PieChartOutlined } from "@ant-design/icons";
import { BiCategory } from "react-icons/bi";
import { BsBuilding } from "react-icons/bs";
import { TbMassage, TbUsers } from "react-icons/tb";
import ModelOrders from "./models/orders";
import ModelServiceGroup from "./models/service-group";
import { HiOutlineClipboardList } from "react-icons/hi";
import { MdOutlineWorkOutline } from "react-icons/md";

// MODELS
import ModelServices from "./models/services";
import ModelUsers from "./models/users";
import ModelReviews from "./models/reviews";
import { AiOutlineStar } from "react-icons/ai";
import ModelWorkers from "./models/workers";
import ModelServicePackages from "./models/service-packages";
import { FiPackage } from "react-icons/fi";
import ModelContacts from "./models/contacts";
import ModelWorkerCVS from "./models/worker_cvs";
import ModelWorkerCVSTypes from "./models/cv_types";
import ModelOrganizations from "./models/organizations";
import UsersContainer from "./containers/Users";
import OrdersContainer from "./containers/Orders";
const menu = [
  {
    href: "/",
    title: "Хянах самбар",
    icon: <PieChartOutlined />,
  },
  {
    href: "/organizations",
    title: " Байгууллагууд",
    icon: <BsBuilding />,
    model: ModelOrganizations,
  },
  {
    href: "/service-group",
    title: " Ангилал",
    icon: <BiCategory />,
    model: ModelServiceGroup,
  },
  {
    href: "/services",
    title: "Үйлчилгээ",
    icon: <TbMassage />,
    model: ModelServices,
  },
  {
    href: "/service-packages",
    title: "Багц үйлчилгээ",
    icon: <FiPackage />,
    model: ModelServicePackages,
  },
  {
    href: "/workers",
    title: "Ажилчид",
    icon: <TbUsers />,
    model: ModelWorkers,
  },
  {
    href: "/users",
    title: "Хэрэглэгчид",
    icon: <TbUsers />,
    model: ModelUsers,
    container: UsersContainer,
  },
  {
    href: "/orders",
    title: "Захиалга",
    icon: <HiOutlineClipboardList />,
    model: ModelOrders,
    container: OrdersContainer,
  },
  {
    href: "/reviews",
    title: "Үнэлгээ",
    icon: <AiOutlineStar />,
    model: ModelReviews,
  },

  {
    href: "/worker_cvs",
    title: "Анкет",
    icon: <TbUsers />,
    model: ModelWorkerCVS,
  },

  {
    href: "/cv_types",
    title: "Анкет төрөл",
    icon: <MdOutlineWorkOutline />,
    model: ModelWorkerCVSTypes,
  },

  {
    href: "/contacts",
    title: "Холбоо барих",
    icon: <AiOutlineStar />,
    model: ModelContacts,
  },
  // {
  //   href: "/reports",
  //   title: "Тайлан",
  //   icon: <FileOutlined />,
  // },
];

export default menu;
