import { Tabs } from "antd";
import * as React from "react";
import MJ from "../MathJAX/MJ";
import Editor from "./Editor";
import EditorRender from "./EditorRender";

const { TabPane } = Tabs;

interface IProps {
  onChange?: (value: any) => void;
  value?: any;
}

const MultiEditor = (props: IProps) => {
  const { value } = props;

  return (
    <main className="flex space-x-10 w-full">
      <Tabs defaultActiveKey="1" className="w-full">
        <TabPane tab="Editor" key="1">
          <div className="w-full order-2 bg-blue-100 shadow-xl p-10 rounded-lg">
            {/* <button type="button" onClick={handlePreview}>
              preview
            </button> */}
            <section
              id="editorjs"
              className="border bg-white border-dashed border-gray-300 p-10 shadow w-full rounded"
            >
              <Editor {...props} key={value ? "not-empty" : "empty"} />
            </section>
          </div>
        </TabPane>
        <TabPane tab="Үр дүн" key="2">
          <div className="w-full border-2 border-red-500 py-10">
            <EditorRender blocks={value || []} />
          </div>
        </TabPane>
      </Tabs>
    </main>
  );
};

export default MultiEditor;
