import * as React from "react";
import numeral from "numeral";

interface IProps {
  value: number;
  suffix?: string;
}

const CurrencyFormat = ({ value, suffix = "₮" }: IProps) => {
  return (
    <>
      {numeral(value).format(`0,0`)}
      {suffix}
    </>
  );
};

export default CurrencyFormat;
