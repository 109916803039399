import { Tag } from "antd";
import { TypeSchema } from ".";

const ModelServiceGroup: TypeSchema = {
  title: "Ангилал",
  collectionName: "service-group",
  resFieldName: "service_groups",
  autoNumber: false,
  crud: {
    find: "/service/group",
    insert: "/service/group/create",
    update: "/service/group/:id/edit",
    delete: "/service/group/:id/remove",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    // {
    //   required: true,
    //   type: "relational",
    //   label: "Сургууль",
    //   name: "schoolId",
    //   relation: "schools",
    //   relationDisplayField: "title",
    //   render: (row: any) => {
    //     return row?.school?.title || "-";
    //   },
    // },
    {
      required: true,
      type: "number",
      label: "#",
      name: "id",
    },
    {
      required: false,
      type: "image",
      label: "Зураг",
      name: "cover_lib_image_id",
      render: (row: any) => {
        return <img src={row?.cover} height={50} />;
      },
    },
    {
      required: true,
      type: "input",
      label: "Код",
      name: "group_code",
    },
    {
      required: true,
      type: "input",
      label: "Нэр",
      name: "name",
    },
    {
      required: true,
      type: "textarea",
      label: "Тайлбар",
      name: "description",
    },
    {
      required: true,
      type: "radio",
      label: "Тун удахгүй",
      name: "is_coming_soon",
      render: (row: any) => {
        return row?.is_coming_soon ? (
          <Tag color="red">Тийм</Tag>
        ) : (
          <Tag>Үгүй</Tag>
        );
      },
      options: [
        {
          title: "Тийм",
          value: true,
        },
        {
          title: "Үгүй",
          value: false,
        },
      ],
    },
  ],
};

export default ModelServiceGroup;
