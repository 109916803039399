import { Card, Statistic } from "antd";
import * as React from "react";
import ChartsArea from "../components/charts/Area";
import Title from "../components/Title";
import Dashboard from "../containers/Dashboard";
interface IProps {}

const StatisticPage = (props: IProps) => {
  return (
    <main>
      <Title title="Хянах самбар" />
      <div className="flex space-x-10 mb-10">
        <div className="w-1/2">
          <Card>
            <Statistic
              title="Нийт захиалга"
              value={25}
              precision={0}
              // valueStyle={{ color: "#3f8600" }}
              // suffix="%"
            />
          </Card>
        </div>
        <div className="w-1/2">
          <Card>
            <Statistic
              title="Амжилттай болсон"
              value={10}
              precision={0}
              valueStyle={{ color: "#2ecc71" }}
              // suffix="%"
            />
          </Card>
        </div>
        <div className="w-1/2">
          <Card>
            <Statistic
              title="Цуцлагдсан захиалга"
              value={"8"}
              precision={0}
              valueStyle={{ color: "#e74c3c" }}
              // suffix="%"
            />
          </Card>
        </div>
        <div className="w-1/2">
          <Card>
            <Statistic
              title="Нийт орлого"
              value={10500000}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              suffix="₮"
            />
          </Card>
        </div>
      </div>
      <Dashboard />
    </main>
  );
};

export default StatisticPage;
