import { Tag } from "antd";
import { TypeSchema } from ".";

const ModelWorkers: TypeSchema = {
  title: "Ажилтан",
  collectionName: "workers",
  resFieldName: "users",
  redirect: false,
  autoNumber: false,
  crud: {
    find: "/worker/list",
    findOne: "/worker/:id/info",
    insert: "/worker/create",
    update: "/worker/:id/edit",
    delete: "/worker/:id/remove",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "image",
      label: "Зураг",
      name: "lib_image_id",
      render: (row: any) => {
        return <img src={row?.image} height={50} />;
      },
    },
    {
      required: true,
      type: "input",
      label: "Овог",
      name: "lastname",
    },
    {
      required: true,
      type: "input",
      label: "Нэр",
      name: "firstname",
    },
    {
      required: true,
      type: "input",
      label: "И-мэйл",
      name: "email",
    },
    {
      required: true,
      type: "password",
      label: "Нууц үг",
      name: "password",
      hideInList: true,
      hideInFormUpdate: true,
    },
    {
      required: true,
      type: "input",
      label: "Утас",
      name: "phone",
      render: (row: any) => {
        return `${row?.country_code || "+976"} ${row?.phone}`;
      },
    },
    {
      required: true,
      type: "date",
      label: "Төрсөн огноо",
      name: "birthday",
      format: "YYYY-MM-DD",
    },
    {
      required: false,
      type: "input",
      label: "Үнэлгээ",
      name: "rate",
      hideInForm: true,
    },
    {
      required: false,
      type: "object-array",
      label: "Туршлага",
      name: "experience",
      hideInList: true,
      fields: [
        {
          required: false,
          type: "textarea",
          label: "Тайлбар",
          name: "description",
        },
      ],
    },
    // {
    //   required: true,
    //   type: "radio",
    //   label: "Статус",
    //   name: "status",
    //   options: [
    //     { title: "Идэвхтэй", value: "active" },
    //     { title: "Идэвхгүй", value: "deactive" },
    //   ],
    //   render: (row: any) => {
    //     return row?.status === "active" ? (
    //       <Tag color="blue">{row?.status}</Tag>
    //     ) : (
    //       <Tag>{row?.status}</Tag>
    //     );
    //   },
    // },
  ],
};

export default ModelWorkers;
