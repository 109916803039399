import * as React from "react";

const NotFound = () => {
  return (
    <main className="h-screen w-full flex items-center justify-center">
      <h1 className="text-3xl">Одоогоор энэ хуудас үүсээгүй байна !</h1>
    </main>
  );
};

export default NotFound;
