import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { TypeSchema } from ".";

const ModelReviews: TypeSchema = {
  title: "Үнэлгээ",
  collectionName: "reviews",
  resFieldName: "reviews",
  autoNumber: false,
  crud: {
    find: "/review/list",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "input",
      label: "Оноо",
      name: "score",
      render: (row: any) => {
        return Array(5)
          .fill(0)
          .map((_, i) => {
            return row?.score > i ? (
              <AiFillStar color="#f1c40f" />
            ) : (
              <AiOutlineStar />
            );
          });
      },
    },
    {
      required: true,
      type: "input",
      label: "Сэтгэгдэл",
      name: "text",
    },
  ],
};

export default ModelReviews;
