import { Tag } from "antd";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { TypeSchema } from ".";

const ModelWorkerCVS: TypeSchema = {
  title: "Ажил орох анкет",
  collectionName: "worker_cvs",
  resFieldName: "worker_cvs",
  redirect: true,
  autoNumber: false,
  crud: {
    find: "/workercv/list",
    // findOne: "/worker/:id/info",
    // insert: "/worker/create",
    // update: "/worker/:id/edit",
    delete: "/workercv/remove/:id",
  },
  triggerActions: {
    preview: (row) => {
      return (
        row?.id && {
          url: "/workercv/read",
          data: {
            read_ids: [row.id],
          },
        }
      );
    },
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "input",
      label: "Төлөв",
      name: "id",
      hideInForm: true,
      render: (row: any) => {
        return row?.is_read ? <Tag>Үзсэн</Tag> : <Tag color="red">Шинэ</Tag>;
      },
    },
    {
      required: true,
      type: "image",
      label: "Зураг",
      name: "image",
    },
    {
      required: true,
      type: "input",
      label: "Хүйс",
      name: "gender",
      render: (gender: string) => {
        return (
          (gender === "male" && "Эрэгтэй") ||
          (gender === "female" && "Эмэгтэй") ||
          "бусад"
        );
      },
    },
    {
      required: true,
      type: "input",
      label: "Овог",
      name: "lastname",
    },
    {
      required: true,
      type: "input",
      label: "Нэр",
      name: "firstname",
    },
    {
      required: true,
      type: "input",
      label: "И-мэйл",
      name: "email",
    },
    {
      required: true,
      type: "input",
      label: "Утас",
      name: "phone",
      render: (row: any) => {
        return `${row?.country_code || "+976"} ${row?.phone}`;
      },
    },
    {
      required: true,
      type: "date",
      label: "Төрсөн огноо",
      name: "birthday",
      format: "YYYY-MM-DD",
    },
    {
      required: true,
      type: "input",
      label: "Ажилд орох огноо",
      name: "available_date",
    },
    {
      required: false,
      type: "input",
      label: "Ажлыг туршлага",
      name: "work_experience",
      hideInList: true,
    },
    {
      required: false,
      type: "input",
      label: "Сонирхож буй ажил",
      name: "interested_in",
      hideInList: true,
      render: (row: any) => {
        return (
          <div>
            {row?.interested_in?.map((inte: any) => (
              <div>
                {inte?.name} - <Tag color="green">{inte?.time}</Tag>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      required: true,
      type: "object-array",
      label: "Хэлний мэдлэг",
      name: "language",
      hideInList: true,
      fields: [
        {
          required: true,
          type: "input",
          label: "Хэл",
          name: "lang",
        },
        {
          required: false,
          type: "number",
          label: "Үнэлгээ",
          name: "rate",
        },
      ],
      render: (row: any) => {
        return (
          <>
            {row?.language?.map((l: any) => (
              <div className="flex space-x-2 items-center">
                <span className="capitalize">{l.lang}</span> <span>-</span>
                {new Array(5).fill(0).map((_, i) => (
                  <span className="text-orange-500 relative top-[2px] text-lg">
                    {i < Number.parseInt(l.rate, 10) ? (
                      <AiFillStar />
                    ) : (
                      <AiOutlineStar />
                    )}
                  </span>
                ))}
              </div>
            ))}
          </>
        );
      },
    },
  ],
};

export default ModelWorkerCVS;
