import { Form, Input, Button, Checkbox, Card, Row, Col, message } from "antd";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
};

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const onFinish = async (values: any) => {
    setLoading(true);
    const res = await signIn(values.username, values.password);
    if (res?.token) {
      message.success("Тавтай морил !" + values.username);
    } else {
      message.error("Таны нэвтрэх нэр эсвэл нууц үг буруу байна !");
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-10">
      <div className="w-full lg:w-3/12 py-10">
        <Card>
          <div className="flex items-center justify-center my-5">
            <img src="/images/logo.svg" />
          </div>
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Хэрэглэгчийн нэр"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input placeholder="demo" size="large" />
            </Form.Item>

            <Form.Item
              label="Нууц үг"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password placeholder="demo" size="large" />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block
                className="my-primary"
                loading={loading}
              >
                Нэвтрэх
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default SignIn;
