import client from "../lib/client";

const apiAuth = {
  signin: async (email: string, password: string) => {
    const res = await client.post("/auth/login", {
      email,
      password,
    });
    return res?.data;
  },
};

export default apiAuth;
