import { Switch, Tag } from "antd";
import { TypeSchema } from ".";

const ModelOrganizations: TypeSchema = {
  title: "Байгууллага",
  collectionName: "organizations",
  resFieldName: "organizations",
  autoNumber: false,
  moreUrl: "/organizations/:id",
  crud: {
    findOne: "/organization/info/:id",
    find: "/organization/list",
    insert: "/organization/create",
    update: "/organization/:id/edit",
    delete: "/organization/:id/remove",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "image",
      label: "Зураг",
      name: "lib_image_id",
      render: (row: any) => {
        return (
          <div>
            <img src={row?.image} height={50} />
          </div>
        );
      },
    },
    {
      required: true,
      type: "input",
      label: "Нэр",
      name: "name",
    },
    {
      required: true,
      type: "input",
      label: "Утас",
      name: "phone",
      render: (row: any) => {
        return `${row?.phone}`;
      },
    },
    {
      required: true,
      type: "select",
      label: "Төлөв",
      name: "status",
      options: [
        { title: "Идэвхтэй", value: "active" },
        { title: "Идэвхгүй", value: "deactive" },
      ],
      render: (row: any) => {
        return row?.status === "active" ? (
          <Tag color="blue">{row?.status}</Tag>
        ) : (
          <Tag>{row?.status}</Tag>
        );
      },
    },
    {
      required: true,
      type: "textarea",
      label: "Тайлбар",
      name: "description",
    },
  ],
};

export default ModelOrganizations;
