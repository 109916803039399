import { Tooltip, Button } from "antd";

import moment from "moment";
import * as React from "react";
import Calendar from "../libs/Calendar";


interface IProps {}

const FullCalendar = (props: IProps) => {
  const [cal, setCal] = React.useState<any>(moment());

  const getCalendarCell = (cell: any) => {
    const temp = [
      {
        name: "Ажилтан 1",
        startAt: "2022-06-27 19:00",
        type: "event",
      },
      {
        name: "Ажилтан 2",
        startAt: "2022-06-23 19:00",
        type: "event",
      },
      {
        name: "Ажилтан 4",
        startAt: "2022-06-10 21:00",
        type: "event",
      },
    ]
      .filter(
        (date: any) =>
          moment(cell).format("YYYY-MM-DD") ===
          moment(date.startAt).format("YYYY-MM-DD")
      )
      .map((pin: any, index: any) => (
        <li
          className={`w-full flex items-center text-gray-700 text-xs border-b-[1px] mb-1 py-1 border-dashed border-t-[0px] border-l-[0px] border-r-[0px] overflow-hidden`}
          // onClick={() => {
          //   console.log(pin);
          // }}
          key={`${
            (typeof pin?.name === "string" && pin?.name) || pin?.name?.mn
          }-${index}`}
        >
          <span
            className={`w-2 h-2 mr-2 rounded-full bg-${
              (pin?.type === "event" && "red-500") ||
              (pin?.type === "skate" && "orange-500") ||
              "blue-500"
            }`}
          ></span>
          <Tooltip
            title={
              (typeof pin?.name === "string" && pin?.name) || pin?.name?.mn
            }
          >
            <div className="flex flex-col">
              {moment(pin?.startAt).format("HH:mm")} цагт
              <strong className="font-bold ">
                {(typeof pin?.name === "string" && pin?.name) || pin?.name?.mn}
              </strong>
            </div>
          </Tooltip>
        </li>
      ));

    return <ul className="events">{temp}</ul>;
  };

  return (
    <>
      <Calendar
        headerRender={() => {
          return (
            <div className="flex items-center justify-between border-b p-5">
              <h3 className="font-bold text-3xl">{moment(cal.format()).format("ll")}</h3>
              <div className="flex items-center space-x-4">
                <Button
                  // size="large"
                  onClick={() => {
                    setCal(moment());
                  }}
                  // type="primary"
                >
                  Яг одоо
                </Button>
                <Button.Group>
                  <Button
                    onClick={() => {
                      const n = moment(cal)
                        .subtract(1, "month")
                        .startOf("month");
                      if (moment().month === n.month) {
                        n.date(moment().get("day"));
                      }
                      setCal(n);
                    }}
                  >
                    Өмнөх сар
                  </Button>
                  <Button
                    onClick={() => {
                      const n = moment(cal).add(1, "month").startOf("month");
                      if (moment().month === n.month) {
                        n.date(moment().get("day"));
                      }
                      setCal(moment(n));
                    }}
                  >
                    Дараагийн сар
                  </Button>
                </Button.Group>
              </div>
            </div>
          );
        }}
        // locale={moment.locales['mn']}
        onSelect={(e) => {
          console.log("onselect: ", e);
        }}
        dateCellRender={getCalendarCell}
        value={cal}
        onChange={(c) => {
          setCal(c);
        }}
      />
    </>
  );
};

export default FullCalendar;
