import { Layout, Menu, Breadcrumb } from "antd";
import {
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  LogoutOutlined,
  BuildOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";
import menu from "../routing-menu";

const { Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

const DefaultLayout = (props: any) => {
  const { logout } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed: any) => {
    setCollapsed(collapsed);
  };
  const { match } = props;
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        theme="light"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <div className="logo flex flex-col">
          <img src="/images/logo.svg" />
          Удирдах хэсэг
        </div>
        <Menu defaultSelectedKeys={[match.path]} mode="inline">
          {menu?.map((m: any) =>
            m?.subMenu ? (
              <Menu.SubMenu title={m.title}>
                {m?.subMenu?.map((m: any) => (
                  <Menu.Item key={m.href} icon={m.icon}>
                    <Link to={m.href}>{m.title}</Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item key={m.href} icon={m.icon}>
                <Link to={m.href}>{m.title}</Link>
              </Menu.Item>
            )
          )}

          <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
            Системээс гарах
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "0 16px" }}>{props.children}</Content>
        {/* <Footer style={{ textAlign: "center" }}>Steppe Link LLC ©2021.</Footer> */}
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
