import { Switch } from "antd";
import { TypeSchema } from ".";
import CurrencyFormat from "../components/CurrencyFormat";

const ModelServicePackages: TypeSchema = {
  title: "Багц",
  collectionName: "service-packages",
  resFieldName: "service_packages",
  autoNumber: false,
  crud: {
    find: "/package/list",
    insert: "/package/create",
    update: "/package/:id/edit",
    delete: "/package/:id/remove",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    // {
    //   required: true,
    //   type: "relational",
    //   label: "Сургууль",
    //   name: "schoolId",
    //   relation: "schools",
    //   relationDisplayField: "title",
    //   render: (row: any) => {
    //     return row?.school?.title || "-";
    //   },
    // },
    {
      required: true,
      type: "number",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "input",
      label: "Нэр",
      name: "name",
    },
    {
      required: true,
      type: "textarea",
      label: "Тайлбар",
      name: "description",
    },
    {
      required: true,
      type: "relational",
      label: "Үйлчилгээ",
      name: "service_id",
      relation: "services",
      relationDisplayField: "name",
      relationField: "id",
      render: (row: any) => {
        return row?.service_name || "-";
      },
    },
    {
      required: true,
      type: "number",
      label: "Төлбөр",
      name: "price",
      render: (row: any) => {
        return <CurrencyFormat value={row?.price} />;
      },
    },
    {
      required: true,
      type: "number",
      label: "Count",
      name: "count",
    },
    {
      required: true,
      type: "radio",
      label: "Харагдац",
      name: "visible",
      options: [
        {
          title: "Ил харагдах",
          value: true,
        },
        {
          title: "Нуух",
          value: false,
        },
      ],
      render: (row: any, trigger: any) => {
        return (
          <Switch
            checked={row?.visible}
            onClick={() => {
              trigger();
            }}
          />
        );
      },
    },
  ],
};

export default ModelServicePackages;
