import client from "../lib/client";

const apiRest = (collectionName: string) => {
  return {
    find: async (params = {}, parentId?: string) => {
      const model = (await import(`../models/${collectionName}`)).default;
      const res = await client.get(
        model?.crud?.find?.replace(":parentId", parentId),
        {
          params: { ...params },
        }
      );
      // const data = model?.withoutPagination
      // ? model.resFieldName
      //   ? res?.data[model.resFieldName]
      //   : res?.data
      // : res?.data;

      // console.log('why? :', data);
      return res?.data;
    },
    // findOne: async (_id: string) => {
    //   const model = (await import(`../models/${collectionName}`)).default;

    //   const collectionRef = mongo
    //     ?.db(model.dbName || DB)
    //     .collection(collectionName);
    //   const result = await collectionRef?.findOne({
    //     _id: new BSON.ObjectId(_id),
    //   });
    //   return result;
    // },
    // listen: async (cb: any) => {
    //   const model = (await import(`../models/${collectionName}`)).default;

    //   const collectionRef = mongo
    //     ?.db(model.dbName || DB)
    //     .collection(collectionName);
    //   if (collectionRef) {
    //     for await (const item of collectionRef.watch()) {
    //       switch (item.operationType) {
    //         case "insert": {
    //           const { documentKey, fullDocument } = item;
    //           console.log(
    //             `new document with _id: ${documentKey}`,
    //             fullDocument
    //           );
    //           cb({ id: documentKey, data: fullDocument });
    //           break;
    //         }
    //       }
    //     }
    //   }
    // },
    insert: async (data: any) => {
      const model = (await import(`../models/${collectionName}`)).default;
      const res = await client.post(model?.crud?.insert, { ...data });
      if (res?.data?.error) {
        throw new Error(res?.data?.error);
      }
      return res?.data;
    },
    delete: async (id: string) => {
      const model = (await import(`../models/${collectionName}`)).default;
      const res = await client.post(
        model?.crud?.delete?.replace(":id", id),
        {}
      );
      if (res?.data?.error) {
        throw new Error(res?.data?.error);
      }
      return res?.data;
    },
    findOne: async (id: string) => {
      const model = (await import(`../models/${collectionName}`)).default;
      const res = await client.get(
        model?.crud?.findOne?.replace(":id", id),
        {}
      );
      if (res?.data?.error) {
        throw new Error(res?.data?.error);
      }
      return res?.data;
    },
    update: async ({ _id, ...values }: any) => {
      const model = (await import(`../models/${collectionName}`)).default;
      const res = await client.post(model?.crud?.update?.replace(":id", _id), {
        ...values,
      });
      if (res?.data?.error) {
        throw new Error(res?.data?.error);
      }
      return res?.data;
    },
    // update: async ({ _id, ...values }: any) => {
    //   const model = (await import(`../models/${collectionName}`)).default;

    //   const collectionRef = mongo
    //     ?.db(model.dbName || DB)
    //     .collection(collectionName);
    //   const result = await collectionRef?.updateOne(
    //     {
    //       _id,
    //     },
    //     {
    //       $set: {
    //         ...values,
    //       },
    //     }
    //   );
    //   if (result?.matchedCount && result?.modifiedCount) {
    //     return result;
    //   } else {
    //     throw new Error("Failed to update the item");
    //   }
    // },
    // delete: async (_id: string) => {
    //   const model = (await import(`../models/${collectionName}`)).default;

    //   const collectionRef = mongo
    //     ?.db(model.dbName || DB)
    //     .collection(collectionName);
    //   await collectionRef?.deleteOne({ _id });
    //   return true;
    // },
  };
};
export default apiRest;
