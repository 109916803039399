import { Tag } from "antd";
import moment from "moment";
import { TypeSchema } from ".";

const ModelContacts: TypeSchema = {
  title: "Холбоо барих",
  collectionName: "contacts",
  resFieldName: "contact_us",
  autoNumber: false,
  crud: {
    find: "/contact/us/list",
    delete: "/contact/us/remove/:id",
  },
  triggerActions: {
    preview: (row) => {
      return {
        url: "/contact/us/read",
        data: {
          read_ids: [row.id],
        },
      };
    },
  },
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "input",
      label: "Төлөв",
      name: "id",
      hideInForm: true,
      render: (row: any) => {
        return row?.is_read ? <Tag>Үзсэн</Tag> : <Tag color="red">Шинэ</Tag>;
      },
    },
    {
      required: true,
      type: "input",
      label: "Нэр",
      name: "name",
    },
    {
      required: true,
      type: "input",
      label: "Мэйл",
      name: "email",
    },
    {
      required: true,
      type: "input",
      label: "Гарчиг",
      name: "title",
    },
    {
      required: true,
      type: "input",
      label: "Агуулга",
      name: "text",
    },
    {
      required: true,
      type: "input",
      label: "Огноо",
      name: "created_at",
      render: (row: any) => {
        return moment(row?.created_at).format("YYYY-MM-DD HH:mm");
      },
    },
  ],
};

export default ModelContacts;
