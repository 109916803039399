import Statistics from "./pages/Statistics";
import { Switch, Route, Link } from "react-router-dom";
import DefaultLayout from "./components/Layout";

import RoutingMenu from "./routing-menu";
import Crud from "./containers/Crud";
import NotFound from "./pages/404";
import CU from "./containers/Crud/CU";
import React from "react";
import OrganizationsContainer from "./containers/Organizations";

const Dashboard = () => {
  return (
    <Switch>
      <Route
        path="/"
        render={(rest) => (
          <DefaultLayout {...rest}>
            <Statistics {...rest} />
          </DefaultLayout>
        )}
        exact
      />

      <Route
        path="/organizations/:parentId"
        render={(rest) => (
          <DefaultLayout {...rest}>
            <OrganizationsContainer {...rest} />
          </DefaultLayout>
        )}
        exact
      />

      {RoutingMenu?.filter((menu) => {
        return !(menu && menu.model && menu.model.redirect);
      }).map((menu) => (
        <Route
          key={`route-${menu.model?.collectionName || menu.href}`}
          path={menu.model ? `/${menu.model?.collectionName}` : menu.href}
          render={(rest) => {
            const Container: any = menu.container;
            return (
              <DefaultLayout {...rest}>
                {menu.model ? (
                  menu.container ? (
                    <Container {...menu.model} />
                  ) : (
                    <Crud {...menu.model} />
                  )
                ) : (
                  <NotFound />
                )}
              </DefaultLayout>
            );
          }}
          exact
        />
      ))}

      {RoutingMenu?.map((menu) =>
        menu.model && menu?.model?.redirect ? (
          [
            <Route
              key={`route-${menu.model?.collectionName}`}
              path={`/${menu.model?.collectionName}`}
              render={(rest) => (
                <DefaultLayout {...rest}>
                  <Crud {...menu.model} />
                </DefaultLayout>
              )}
              exact
            />,

            <Route
              key={`route-${menu.model?.collectionName}-create`}
              path={`/${menu.model?.collectionName}/create`}
              render={(rest) => (
                <DefaultLayout {...rest}>
                  <CU {...menu.model} />
                </DefaultLayout>
              )}
              exact
            />,

            <Route
              key={`route-${menu.model?.collectionName}-id`}
              path={`/${menu.model?.collectionName}/edit/:id`}
              render={(rest) => (
                <DefaultLayout {...rest}>
                  <CU {...menu.model} />
                </DefaultLayout>
              )}
              exact
            />,
          ]
        ) : (
          <Route
            key={`route-${menu.model?.collectionName || menu.href}`}
            path={menu.model ? `/${menu.model?.collectionName}` : menu.href}
            render={(rest) => (
              <DefaultLayout {...rest}>
                {menu.model ? <Crud {...menu.model} /> : <NotFound />}
              </DefaultLayout>
            )}
            exact
          />
        )
      )}

      {/* <Route
        path="/parking"
        render={(rest) => (
          <DefaultLayout {...rest}>
            <ParkingPage {...rest} />
          </DefaultLayout>
        )}
      /> */}

      {/* <Route
        path="/users"
        render={(rest) => (
          <DefaultLayout {...rest}>
            <UsersPage {...rest} />
          </DefaultLayout>
        )}
        exact
      /> */}
      {/* <Route
        path="/users/:id"
        render={(rest) => (
          <DefaultLayout {...rest}>
            <ProfilePage {...rest} />
          </DefaultLayout>
        )}
        exact
      /> */}

      {/* <Route
        path="/reports"
        render={(rest) => (
          <DefaultLayout {...rest}>
            <ReportPage {...rest} />
          </DefaultLayout>
        )}
        exact
      />
      <Route
        path="/tests"
        render={(rest) => (
          <DefaultLayout {...rest}>
            <Tests {...rest} />
          </DefaultLayout>
        )}
        exact
      /> */}
    </Switch>
  );
};

export default Dashboard;
