import { Button, Tag } from "antd";
import moment from "moment";
import { TypeSchema } from ".";
import CurrencyFormat from "../components/CurrencyFormat";

const ORDER_STATUSES: any = {
  pending: "Төлбөр хүлээгдэж байгаа",
  paid: "Төлбөр төлөгдсөн",
  shipping: "Очиж байгаа",
  completed: "Дууссан",
  cancelled: "Цуцалсан",
  expired: "Хугацаа дууссан",
  closed: "Хаагдсан",
};

const COLORS = {
  pending: "yellow",
  paid: "geekblue",
  shipping: "blue",
  completed: "green",
  cancelled: "gray",
  expired: "red",
  closed: "#000",
};

const ModelOrders: TypeSchema = {
  title: "Захиалга",
  collectionName: "orders",
  resFieldName: "orders",
  autoNumber: false,
  crud: {
    find: "/order/list",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  actions: [],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "input",
      label: "Төлөв",
      name: "status",
      render: (row: any) => {
        return (
          <Tag color={COLORS[row?.status]}>{ORDER_STATUSES[row?.status]}</Tag>
        );
      },
    },
    {
      required: true,
      type: "input",
      label: "Төрөл",
      name: "type",
      hideInForm: true,
      hideInList: true,
    },
    {
      required: true,
      type: "input",
      label: "Үнэ",
      name: "price",
      render: (row: any) => {
        return <CurrencyFormat value={row?.price} />;
      },
    },
    {
      required: true,
      type: "input",
      label: "Үйлчилгээ",
      name: "service_name",
    },
    {
      required: true,
      type: "input",
      label: "Үргэлжлэх хугацаа",
      name: "service_duration",
      render: (row: any) => {
        return `${row?.service_duration} мин`;
      },
    },
    {
      required: true,
      type: "input",
      label: "Эхлэх цаг",
      name: "service_start_date",
      render: (row: any) => {
        return moment(row?.service_start_date).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      required: true,
      type: "input",
      label: "Дуусах цаг",
      name: "service_end_date",
      render: (row: any) => {
        return moment(row?.service_end_date).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      required: true,
      type: "input",
      label: "Байгууллага",
      name: "organization_name",
      // render: (row: any) => {
      //   return `${row?.worker_lastname} ${row?.worker_firstname}`;
      // },
    },
    {
      required: true,
      type: "input",
      label: "Ажилтан",
      name: "worker_id",
      render: (row: any) => {
        return `${row?.worker_lastname} ${row?.worker_firstname}`;
      },
    },
    {
      required: true,
      type: "input",
      label: "Хэрэглэгч",
      name: "user_id",
      render: (row: any) => {
        return `${row?.user_lastname} ${row?.user_firstname}`;
      },
    },

    {
      required: true,
      type: "input",
      label: "Хаяг",
      name: "address_city",
      hideInList: true,
      render: (row: any) => {
        return `${row?.address_city || ""}, ${row?.address_district || ""}, ${
          row?.address_unit || ""
        }, ${row?.address_1 || ""}, ${row?.address_2 || ""}`;
      },
    },
    {
      required: true,
      type: "input",
      label: "Огноо",
      name: "created_at",
      render: (row: any) => {
        return moment(row?.created_at).format("YYYY-MM-DD HH:mm");
      },
    },
  ],
};

export default ModelOrders;
