import client from "../lib/client";

const apiAssets = {
  insert: async (file: any) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await client.post("/image/library/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  },
};
export default apiAssets;
