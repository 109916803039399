import { useRef, useState, useLayoutEffect, useEffect } from "react";

function MJ({ tex }: any) {
  const m = useRef<any>();
  useEffect(() => {
    if (window["typesetInput"]) {
      window["typesetInput"](m.current, tex);
    }
  }, [tex]);
  return <div ref={m}></div>;
}

export default MJ;
