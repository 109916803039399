import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Space,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import * as React from "react";
import RelationInput from "../../components/RelationInput";
import Upload from "../../components/Upload";
import MultiEditor from "../../components/MultiEditor";
import Editor from "../../components/Editor";
import moment from "moment";
import DatePicker from "../../components/libs/DatePicker";

interface IProps {
  fields?: any[];
  submit: (
    values: any,
    _id?: any,
    error?: (error: boolean) => void
  ) => Promise<any>;
  close?: any;
  editData?: any;
  mutationAssets: any;
}

const CustomForm = ({
  fields,
  submit,
  close,
  editData,
  mutationAssets,
}: IProps) => {
  const filesRef = React.useRef<any>({});
  const [form] = Form.useForm();
  React.useEffect(() => {
    if (editData) {
      const tmp = { ...editData };
      // const relations = fields?.filter((f) => f.relation);
      // relations?.forEach((r) => {
      //   tmp[r.name] = tmp[r.name]?.toString();
      // });
      const images = fields?.filter((f) => f.type === "image");
      images?.forEach(({ name }) => {
        if (tmp[name]?.trim() !== "") {
          filesRef.current[name] = tmp[name];
        }
      });

      tmp.lib_image_id = tmp?.image;
      tmp.cover_lib_image_id = tmp?.cover;

      if (tmp.birthday) {
        tmp.birthday = moment(tmp.birthday);
      }

      form.setFieldsValue(tmp);
    }
  }, [editData]);

  const generateForm = (f: any, rest?: any) => {
    return f.type === "object-array" ? (
      <Form.List name={f.name} initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, i) => (
              <div className="mb-5">
                <Card
                  title={`${f.label} - ${i + 1}`}
                  extra={
                    <>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </>
                  }
                >
                  {f.fields?.map((ff: any) =>
                    generateForm(ff, {
                      ...restField,
                      name: [name, ff.name],
                      label: ff.label,
                    })
                  )}
                </Card>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Нэмэх
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    ) : (
      <Form.Item
        key={f.name}
        label={f.label}
        name={f.name}
        labelCol={{ span: 24 }}
        rules={[{ required: f.required }]}
        {...rest}
      >
        {((f.type === "input" || f.type === "email") && (
          <Input
            type={f.type === "input" ? "text" : f.type}
            size="large"
            placeholder={f.placeholder || ""}
          />
        )) ||
          (f.type === "number" && (
            <InputNumber
              style={{ width: "100%" }}
              size="large"
              placeholder={f.placeholder || ""}
            />
          )) ||
          (f.type === "textarea" && (
            <Input.TextArea size="large" placeholder={f.placeholder || ""} />
          )) ||
          (f.type === "select" && (
            <Select size="large" placeholder={f.placeholder || ""}>
              {f.options?.map((op: any) => (
                <Select.Option value={op.value}>{op.title}</Select.Option>
              ))}
            </Select>
          )) ||
          (f.type === "date" && (
            <DatePicker
              style={{
                width: "100%",
              }}
              size="large"
            />
          )) ||
          (f.type === "radio" && (
            <Radio.Group>
              {f?.options?.map((op: any) => (
                <Radio value={op.value}>{op.title}</Radio>
              ))}
            </Radio.Group>
          )) ||
          (f.type === "image" && (
            <Upload
              handleFile={(file: any) => {
                filesRef.current[f.name] = file;
              }}
            />
          )) ||
          (f.type === "relational" && (
            <RelationInput
              collectionName={f.relation}
              displayField={f.relationDisplayField}
              relationField={f.relationField}
            />
          )) ||
          (f.type === "multi-editor" && <MultiEditor />) ||
          (f.type === "password" && <Input.Password size="large" />) ||
          (f.type === "editor" && <Editor />)}
      </Form.Item>
    );
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 25 }}
      initialValues={{}}
      validateMessages={{
        required: "Бөглөх шаардлагатай !",
      }}
      onFinish={async (values) => {
        const tmp = JSON.parse(JSON.stringify(values));

        const imagePromises: any = [];
        Object.keys(filesRef.current || {}).forEach((key) => {
          if (typeof filesRef.current[key] === "string") {
            imagePromises.push(Promise.resolve({ id: filesRef.current[key] }));
          } else {
            imagePromises.push(
              mutationAssets.mutateAsync(filesRef.current[key])
            );
          }
        });

        const res = await Promise.all(imagePromises);

        Object.keys(filesRef.current || {}).forEach((key, i) => {
          tmp[key] = res[i]?.id;
        });

        const isDate = function (date: any) {
          return (
            new Date(date).toString() !== "Invalid Date" &&
            !isNaN(new Date(date).getDate())
          );
        };

        Object.keys(tmp).forEach((key) => {
          const field = fields?.find((f) => f.name === key);
          if (field.type === "date") {
            tmp[key] = moment(tmp[key])?.format(field.format || "YYYY-MM-DD");
          } else if (field.type === "object-array") {
            tmp[key]?.forEach((obj: any, j: any) => {
              Object.keys(obj).forEach((key2) => {
                const nestedField = field.fields.find(
                  (f: any) => f.name === key2
                );
                if (nestedField?.type === "date") {
                  if (tmp[key] && tmp[key][j] && tmp[key][j][key2]) {
                    tmp[key][j][key2] = moment(tmp[key][j][key2])?.format(
                      nestedField.format || "YYYY-MM-DD"
                    );
                  }
                }
              });
            });
          }
        });
        submit(tmp, editData?.id, (success: boolean) => {
          if (success) {
            form.resetFields();
          }
        });
      }}
      onFinishFailed={() => {
        message.warning("Уучлаарай мэдээллээ бүрэн бөглөнө үү !");
      }}
      autoComplete="off"
    >
      <section>
        {fields
          ?.filter((f) => f.name !== "id" && !f.hideInForm)
          ?.filter((f) => {
            if (editData) {
              return !f.hideInFormUpdate;
            }
            return true;
          })
          .map((f) => generateForm(f))}

        <div className="flex items-center border-t-2 border-red-500 justify-end space-x-4">
          <Button onClick={close}>Цуцлах</Button>
          <Button htmlType="submit" type="primary">
            Хадгалах
          </Button>
        </div>
      </section>
    </Form>
  );
};

export default CustomForm;
