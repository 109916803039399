import * as React from "react";
import Crud from "./Crud";
import { TypeSchema } from "../models";
import { Col, DatePicker, Input, Radio, Row, Space } from "antd";
import useDebounce from "../hooks/useDebounce";

const OrdersContainer = (props: TypeSchema) => {
  const [value, setValue] = React.useState<any>("");
  const [filter, setFilter] = React.useState("id");
  const search = useDebounce(value, 200);
  React.useEffect(() => {
    setValue("");
  }, [filter]);
  return (
    <>
      <Crud
        {...props}
        filters={{}}
        renderFilters={() => (
          <div style={{ marginBottom: 20 }}>
            <h3 style={{ marginBottom: 0 }}>Хайлтын хэсэг</h3>
            <Row align="middle" gutter={20}>
              <Col>
                <h4>Филтер сонгох:</h4>
                <Radio.Group
                  defaultValue="id"
                  buttonStyle="solid"
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                >
                  <Radio.Button value="id">Захиалгын дугаар</Radio.Button>
                  <Radio.Button value="user">Утас</Radio.Button>
                  <Radio.Button value="created_at">Огноо</Radio.Button>
                </Radio.Group>
              </Col>
              <Col flex={1}>
                <h4>Хайх утга:</h4>
                {filter === "created_at" ? (
                  <DatePicker
                    onChange={(value) => {
                      setValue(value);
                    }}
                    value={typeof value !== "string" && value}
                  />
                ) : (
                  <Input
                    placeholder={"Хайх утга"}
                    size="large"
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    value={value}
                  />
                )}
              </Col>
            </Row>
          </div>
        )}
      />
    </>
  );
};

export default OrdersContainer;
