import ReactDOM from "react-dom";
import React, { Component, useRef } from "react";

import { createReactEditorJS } from "react-editor-js";

import EDITOR_JS_TOOLS from "./plugins/index";

const ReactEditorJS = createReactEditorJS();

const Editor = (props: any) => {
  const coreRef = useRef<any>();
  const { value, onChange } = props;
  return (
    <ReactEditorJS
      tools={EDITOR_JS_TOOLS}
      defaultValue={{ blocks: value }}
      onInitialize={(core) => {
        coreRef.current = core;
      }}
      onChange={async () => {
        const res = await coreRef.current?.save();
        onChange(res.blocks);
      }}
    />
  );
};

export default Editor;
