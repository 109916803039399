import React, { createContext, useEffect, useState } from "react";
import apiAuth from "../api/auth";
interface IContext {
  user?: Realm.User | null;
  // login: any;
  logout?: any;
  loaded?: any;
  loginAnonymous?: () => void;
  signIn?: any;
}

const AuthContext = createContext<IContext>({});

export const AuthProvider = (props: any) => {
  const [user, setUser] = useState<any>();
  const [loaded, setLoaded] = useState(false);

  const loginAnonymous = async () => {};

  const signIn = async (email: string, password: string) => {
    try {
      const res: any = await apiAuth.signin(email, password);
      if (res?.token) {
        localStorage.setItem("serviceToken", res?.token);
        setUser(res);
        return res;
      } else {
        return undefined;
      }
    } catch (e) {
      console.log("Custom Error: ", e);
      return undefined;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("serviceToken");
    if (token) {
      setUser({ token });
    }
    setLoaded(true);
  }, []);

  const logout = async () => {
    setUser(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loaded,
        loginAnonymous,
        logout,
        signIn,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
