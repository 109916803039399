import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import SignIn from "./pages/SignIn";
import Dashboard from "./Dashboard";
import { AuthConsumer, AuthProvider } from "./contexts/AuthContext";
import useAuth from "./hooks/useAuth";

import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to={"/signin"} />
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }: any) => {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? <Component {...props} /> : <Redirect to={"/"} />
      }
    />
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <AuthConsumer>
            {({ loaded }) =>
              loaded && (
                <Switch>
                  <PublicRoute path="/signin" component={SignIn} exact />
                  <PrivateRoute path="/" component={Dashboard} />
                </Switch>
              )
            }
          </AuthConsumer>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
