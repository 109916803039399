import { Checkbox, Divider } from "antd";
import * as React from "react";
import { useQuery } from "react-query";
import apiRest from "../../api/rest";

export default function WorkerList() {
  const query = useQuery("workers", () => apiRest("workers").find());
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  console.log("query: ", query?.data);

  return (
    <div>
      <Divider>
        <h1 className="text-base m-0">Ажилтны жагсаалт</h1>
      </Divider>
      {query?.data?.map((w: any, i: number) => (
        <div>
          {/* <span>{i + 1}. </span> */}
          <Checkbox>
            {w?.lastname || "-"} {w?.firstname || "-"}
          </Checkbox>
          <Divider style={{ margin: 10 }} />
        </div>
      ))}
    </div>
  );
}
