import { Switch } from "antd";
import { TypeSchema } from ".";
import CurrencyFormat from "../components/CurrencyFormat";
import MakeRequest from "../components/MakeRequest";

const ModelServices: TypeSchema = {
  title: "Үйлчилгээ",
  collectionName: "services",
  resFieldName: "services",
  autoNumber: false,
  crud: {
    find: "/service/list",
    insert: "/service/create",
    update: "/service/:id/edit",
    delete: "/service/:id/remove",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "image",
      label: "Зураг",
      name: "lib_image_id",
      render: (row: any) => {
        return row?.image !== "" ? <img src={row?.image} height={50}/> : '-' 
      }
    },
    {
      required: true,
      type: "input",
      label: "Нэр",
      name: "name",
    },
    {
      required: true,
      type: "relational",
      label: "Ангилал",
      name: "service_group",
      relation: "service-group",
      relationDisplayField: "name",
      relationField: "id",
      render: (row: any) => {
        console.log("testing: ", row);
        // return row?.school?.title || "-";
        return row?.service_group;
      },
    },
    {
      required: true,
      type: "textarea",
      label: "Тайлбар",
      name: "description",
    },
    {
      required: true,
      type: "number",
      label: "Хугацаа (минут)",
      name: "duration",
      render: (row: any) => {
        return `${row?.duration} мин`;
      },
    },
    {
      required: true,
      type: "number",
      label: "Төлбөр",
      name: "price",
      render: (row: any) => {
        return <CurrencyFormat value={row?.price} />;
      },
    },
    {
      required: true,
      type: "radio",
      label: "Харагдац",
      name: "visible",
      options: [
        {
          title: "Ил харагдах",
          value: true,
        },
        {
          title: "Нуух",
          value: false,
        },
      ],
      render: (row: any, trigger: any) => {
        return (
          <Switch
            checked={row?.visible}
            onClick={() => {
              trigger();
            }}
          />
        );
      },
    },
  ],
};

export default ModelServices;
