import { Modal, Table } from "antd";
import * as React from "react";

import FullCalendar from "../../components/FullCalendar";
import WorkerList from "../../components/WorkerList";

function createData(...days: any[]) {
  return {
    title: days[0],
    mon: days[1],
    tue: days[2],
    wed: days[3],
    thu: days[4],
    fri: days[5],
    sat: days[6],
    sun: days[7],
  };
}

const rows = [
  createData("Ажиллах", 12, 24, 12, 12, 24, 12, 12),
  createData("Ажиллахгүй", 12, 12, 12, 12, 6, 0, 0),
];

export default function WorkerTable() {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <Modal
        footer={null}
        width={1080}
        open={visible}
        onCancel={() => setVisible(false)}
      >
        <div className="flex">
          <div className="w-1/4 relative overflow-y-scroll max-h-[100vh] pr-5">
            <div className="sticky top-0 left-0">
              <WorkerList />
            </div>
          </div>
          <div className="w-[2px] h-screen bg-gray-100"></div>
          <div className="w-3/4">
            <FullCalendar />
          </div>
        </div>
      </Modal>
      <Table
        bordered
        pagination={false}
        size="small"
        columns={[
          {
            title: "7 хоног",
            dataIndex: "title",
            render: (title: string, __, i) => {
              return i === 0 ? (
                <span className="text-green-500 font-bold">{title}</span>
              ) : (
                <span className="text-red-500 font-bold">{title}</span>
              );
            },
          },
          {
            title: "Даваа",
            dataIndex: "mon",
          },
          {
            title: "Мягмар",
            dataIndex: "tue",
          },
          {
            title: "Лхагва",
            dataIndex: "wed",
          },
          {
            title: "Пүрэв",
            dataIndex: "thu",
          },
          {
            title: "Баасан",
            dataIndex: "fri",
          },
          {
            title: "Бямба",
            dataIndex: "sat",
          },
          {
            title: "Ням",
            dataIndex: "sun",
          },
        ]}
        dataSource={rows}
        footer={() => (
          <div
            onClick={() => {
              setVisible(true);
            }}
            className="text-right select-none cursor-pointer underline underline-offset-2"
          >
            Дэлгэрэнгүй харах
          </div>
        )}
      />
    </>
  );
}
