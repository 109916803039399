import * as React from "react";
import Crud from "./Crud";
import { TypeSchema } from "../models";
import { Input, Space } from "antd";
import useDebounce from "../hooks/useDebounce";

const UsersContainer = (props: TypeSchema) => {
  const [value, setValue] = React.useState("");
  const search = useDebounce(value, 200);
  return (
    <>
      <Crud
        {...props}
        filters={{ search }}
        renderFilters={() => (
          <div style={{ marginBottom: 20 }}>
            <h3>Хайлтын хэсэг</h3>
            <Input
              placeholder="Хэрэглэгчийн нэр болон утасны дугаараар хайх"
              size="large"
              onChange={(e) => {
                setValue(e.target.value);
              }}
              value={value}
            />
          </div>
        )}
      />
    </>
  );
};

export default UsersContainer;
