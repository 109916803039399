import { TypeSchema } from ".";

const ModelWorkerCVSTypes: TypeSchema = {
  title: "Анкет төрөл",
  collectionName: "cv_types",
  resFieldName: "cv_types",
  redirect: false,
  autoNumber: false,
  crud: {
    find: "/workercv/type/list",
    // findOne: "/worker/:id/info",
    insert: "/workercv/type/create",
    update: "/workercv/type/edit/:id",
    delete: "/workercv/type/remove/:id",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },

    {
      required: true,
      type: "input",
      label: "Гарчиг",
      name: "name",
    },
  ],
};

export default ModelWorkerCVSTypes;
