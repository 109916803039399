// /organization/service/list/{organization_id}

import { Tag } from "antd";
import { TypeSchema } from ".";

const ModelOrgsUsers: TypeSchema = {
  title: "Ажилчид",
  collectionName: "orgs-users",
  resFieldName: "organization_users",
  autoNumber: false,
  id: "organization_id",
  crud: {
    find: "/organization/user/list/:parentId",
    insert: "/organization/user/add",
    update: "/organization/user/:id/edit",
    delete: "/organization/user/:id/remove",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "relational",
      label: "Хэрэглэгч",
      name: "user_id",
      relation: "workers",
      relationDisplayField: (row: any) => `${row?.firstname} ${row?.lastname}`,
      relationField: "id",
      render: (row: any) => {
        return `${row?.firstname} ${row?.lastname}`;
      },
    },
    {
      required: true,
      type: "select",
      label: "Үүрэг",
      name: "role",
      options: [
        { title: "Manager", value: "manager" },
        { title: "Worker", value: "worker" },
      ],
      render: (row: any) => {
        return <Tag>{row?.role}</Tag>;
      },
    },
    {
      required: true,
      type: "select",
      label: "Төлөв",
      name: "status",
      options: [
        { title: "Идэвхтэй", value: "active" },
        { title: "Идэвхгүй", value: "deactive" },
      ],
      render: (row: any) => {
        return row?.status === "active" ? (
          <Tag color="blue">{row?.status}</Tag>
        ) : (
          <Tag>{row?.status}</Tag>
        );
      },
    },
  ],
};

export default ModelOrgsUsers;
