import { Input, message } from "antd";
import * as React from "react";

interface IProps {
  value: any;
  onChange: (value: any) => void;
  handleFile?: (file: any) => void;
}

const Upload = (props: IProps | any) => {
  const { value, onChange, handleFile } = props;

  const [preview, setPreview] = React.useState<any>(props.value);
  const [file, setFile] = React.useState<any>(null);
  const handleChange = (e: any) => {
    const [file] = e.target.files;
    if (file) {
      const size = file.size / 1024 / 1024;
      if (size <= 1) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          setPreview(reader.result);
          onChange && onChange(reader.result);
          setFile(file);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        message.warning(
          `Уучлаарай таны файлын хэмжээ ${size?.toFixed(
            2
          )}mb байна. 1mb аас бага байх ёстой!`
        );
      }
    }
  };

  React.useEffect(() => {
    setPreview(value);
  }, [value]);

  React.useEffect(() => {
    handleFile(file);
  }, [file]);

  return (
    <div>
      {preview && <img src={preview} className={"w-1/3 mx-auto mb-2"} />}
      <Input
        type="file"
        placeholder="Select image"
        accept="image/*"
        multiple={false}
        onChange={handleChange}
      />
    </div>
  );
};

export default Upload;
