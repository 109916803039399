import { TypeSchema } from ".";

const ModelUsers: TypeSchema = {
  title: "Хэрэглэгчид",
  collectionName: "users",
  resFieldName: "users",
  autoNumber: false,
  crud: {
    find: "/user/list",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "input",
      label: "Овог",
      name: "lastname",
    },
    {
      required: true,
      type: "input",
      label: "Нэр",
      name: "firstname",
    },
    {
      required: true,
      type: "input",
      label: "Зураг",
      name: "image",
      hideInList: true,
    },
    {
      required: true,
      type: "input",
      label: "Утас",
      name: "phone",
      render: (row: any) => {
        return `${row?.country_code} ${row?.phone}`;
      },
    },
    {
      required: true,
      type: "input",
      label: "Төрсөн огноо",
      name: "birthday",
      hideInList: true,
    },
    {
      required: false,
      type: "input",
      label: "Үйлчлүүлсэн давтамж",
      name: "count",
      hideInForm: true,
      render: (row: any) => {
        return row?.count || 0
      }
    },
  ],
};

export default ModelUsers;
