import axios from "axios";
const client = axios.create({
  baseURL: process.env.REACT_APP_API,
});

client.interceptors.request.use(
  async (config: any) => {
    const serviceToken = localStorage.getItem("serviceToken");
    if (serviceToken) {
      config.headers.Authorization = `${serviceToken}`;
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  function (response) {
    if (response.data?.code === 403) {
      localStorage.removeItem("serviceToken");
      window.location.href = "/";
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default client;
