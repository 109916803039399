// /organization/service/list/{organization_id}

import { Tag } from "antd";
import { TypeSchema } from ".";

const ModelOrgsServices: TypeSchema = {
  title: "Үйлчилгээ",
  collectionName: "orgs-services",
  resFieldName: "organization_services",
  autoNumber: false,
  id: "organization_id",
  crud: {
    find: "/organization/service/list/:parentId",
    insert: "/organization/service/add",
    update: "/organization/service/:id/edit",
    delete: "/organization/service/:id/remove",
  },
  // columns: [
  //   {
  //     title: "",
  //   },
  // ],
  fields: [
    {
      required: true,
      type: "input",
      label: "#",
      name: "id",
    },
    {
      required: true,
      type: "relational",
      label: "Үйлчилгээ",
      name: "service_id",
      relation: "services",
      relationDisplayField: "name",
      relationField: "id",
      render: (row: any) => {
        return row?.service_name || "-";
      },
    },
    {
      required: true,
      type: "select",
      label: "Төлөв",
      name: "status",
      options: [
        { title: "Идэвхтэй", value: "active" },
        { title: "Идэвхгүй", value: "deactive" },
      ],
      render: (row: any) => {
        return row?.status === "active" ? (
          <Tag color="blue">{row?.status}</Tag>
        ) : (
          <Tag>{row?.status}</Tag>
        );
      },
    },
  ],
};

export default ModelOrgsServices;
